export const Minixs = {
  methods: {
    changePhone(mobile) {
      let phone = "";
      let value = mobile.replace(/\D/g, '').substring(0, 21);
      let valueLen = value.length;
      if (valueLen > 3 && valueLen < 8) {
        value = `${value.substr(0, 3)}-${value.substr(3)}`;
      } else if (valueLen >= 8) {
        value = `${value.substr(0, 3)}-${value.substr(3, 3)}-${value.substr(6)}`;
      }
      phone = value
      return phone
    },
    changePrice(val, decimals) {
      let value = val.split(",").join("");
      const digitsRE = /(\d{3})(?=\d)/g
      value = parseFloat(value);
      if (!isFinite(value) || (!value && value !== 0)) return '';
      decimals = decimals != null ? decimals : 2;//
      var stringified = Math.abs(value).toFixed(decimals)
      var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
      var i = _int.length % 3;
      var head = i > 0 ?  (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
      var price = head + _int.slice(i).replace(digitsRE, '$1,') ;
      return price
    },
  },
}
